let payment = {
  path: '/consumption',
  meta: {
    requiresAuth: true,
  },
  components: {
    default: () =>
      import(/* webpackChunkName: "Consumption" */ '../pages/Payment.vue'),

    // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
  },
}

let pointPayment = {
  path: '/point-consumption',
  meta: {
    requiresAuth: true,
  },
  components: {
    default: () =>
      import(/* webpackChunkName: "Consumption" */ '../pages/PointPayment.vue'),

    // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
  },
}

let paymentResult = {
    path: '/consumption-result',
    meta: {
      requiresAuth: true,
    },
    components: {
        default: () => import(/* webpackChunkName: "ConsumptionResult" */ '../pages/PaymentResult.vue'),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
    }
}

let paymentError = {
    path: '/consumption-error',
    meta: {},
    components: {
        default: () => import(/* webpackChunkName: "ConsumptionError" */ '../pages/PaymentError.vue'),
        // Footer: () => import(/* webpackChunkName: "Footer" */ '../components/Layout/Footer.vue')
    }
} 

export {payment, pointPayment, paymentResult, paymentError}