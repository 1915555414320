// import moment from 'moment-timezone';
import i18n from '@/i18n'
import { getPaymentImage } from '@/utils/utils'
// function getPaymentImage(service, type) {
//   let icon = null;
//   let method = `${service}-${type}`;
//   switch(method) {
//     case 'tappay-apple':
//       // icon = require('@/assets/img/p_apple.png');
//       break;
//     case 'tappay-google':
//       //icon = require('@/assets/img/p_google.png');
//       break;
//     case 'tappay-line':
//     case 'linepay-linepay':
//       //icon = require('@/assets/img/p_line.png');
//       break;
//     case 'easywallet-easywallet':
//       //icon = require('@/assets/img/p_easy.png');
//       break;
//     case 'tappay-jko':
//       //icon = require('@/assets/img/p_jko.png');
//       break;
//     case 'taiwanpay-twqrp':
//       //icon = require('@/assets/img/p_taiwan.png');
//       break;
//     default:
//       icon = null;
//       break;
//   }
//   return icon;
// }

const state = () => ({
  defaultPointLogo: require('@/assets/icon/ptTaoyuan.svg'),
  defaultPointLogoW: require('@/assets/icon/ptTaoyuanW.svg'),
  storeInfo: {},
  supportedPaymentMethods: [],
  supportedPointTypes: [],
  userPoints: [],
  orderInfo: {},
  systemNotice: {},
});

// getters
const getters = {
  // 會員在此特店可以使用的點數
  getAvailablePointTypes(state) {
    let available = [];

    state.supportedPointTypes.forEach((point) => {
      const found = state.userPoints.find(
        (p) => p.pointCode === point.pointCode
      );
      console.log('found:', found);
      if (found) {
        found.pointsToCurrency = point.pointsToCurrency;
        // 去掉小數部分
        found.redeemableDollars = Math.trunc(
          (found.pointBalance / point.pointsToCurrency.points) *
            point.pointsToCurrency.currency
        );
        found.pointLogo = point.pointLogo || state.defaultPointLogo;
        found.pointLogoW = point.pointLogoW || state.defaultPointLogoW;
        found.entityId = point.entityId;
        found.entityType = point.entityType;
        available.push(found);
      }
    });
    return available;
  },
  // 會員在此特店尚未開通的點數
  getApplicablePointTypes(state) {
    let applicable = [];
    state.supportedPointTypes.forEach((point) => {
      const found = state.userPoints.find(
        (p) => p.pointCode === point.pointCode
      );
      // console.log('found:', found);
      if (!found) {
        if (!point.pointLogo) {
          point.pointLogo = state.defaultPointLogo;
        }
        if (!point.pointLogoW) {
          point.pointLogoW = state.defaultPointLogoW;
        }
        applicable.push(point);
      }
    });
    return applicable;
  },
  // getIsTokenExpired(state) {
  //   const currentTs = moment().unix();
  //   if (state.orderInfo && state.orderInfo.expirationTs) {
  //     if (state.orderInfo.expirationTs >= currentTs) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   } else {
  //     return false;
  //   }
  // },
  getUserPoints(state) {
    return state.userPoints;
  },
  getStorePoints(state) {
    return state.supportedPointTypes;
  },
  getDefaultPointLogo(state) {
    return state.defaultPointLogo;
  },
  getStoreInfo(state) {
    return state.storeInfo;
  },
  getSupportedPaymentMethods(state) {
    console.log('state.supportedPaymentMethods', JSON.stringify(state.supportedPaymentMethods))
    let methods = [
      {
          paymentService: 'offline',
          paymentType: 'offline',
          isBrowserSupported: true,
          isEnabled: 1,
          name: i18n.t('Offline Payment'),
          paymentServiceId: -1, 
          icon: null,
          label: i18n.t('Offline Payment')
        }
    ];
    state.supportedPaymentMethods.forEach(method => {
      // console.log(method.paymentService)
      let obj = {...method};
      obj.icon = getPaymentImage(obj.paymentService, obj.paymentType);
      obj.label = obj.icon ? null : i18n.t('Credit Card');
      methods.push(obj);
    });
    // methods.unshift({
    //   paymentService: 'offline',
    //   paymentType: 'offline',
    //   isBrowserSupported: true,
    //   isEnabled: 1,
    //   name: i18n.t('Offline Payment'),
    //   paymentServiceId: 999, 
    //   icon: null,
    //   label: i18n.t('Offline Payment')
    // })
    
    console.log('methods:', JSON.stringify(methods));
    return methods;
  },
  getSystemNotice(state) { 
    return state.systemNotice; 
  },
};

// mutations
const mutations = {
  updateStoreInfo(state, store) {
    state.storeInfo = { ...store };
  },
  updateSupportedPaymentMethods(state, methods) {
    state.supportedPaymentMethods = [...methods];
  },
  updateSupportedPointTypes(state, points) {
    state.supportedPointTypes = [...points];
  },
  updateUserPoints(state, points) {
    state.userPoints = [...points];
  },
  updateOrderInfo(state, order) {
    state.orderInfo = { ...order };
  }, 
  updateSystemNotice(state, data) {
    state.systemNotice = { ...data };
  }, 
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
