import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

const isCarrierBarcodeFormat = (code) => {
  let patt = /^\/[0-9A-Z.+-]{7}$/;
  return patt.test(code);
}


extend('required', required);

extend('minmax', {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max;
  },
  params: ['min', 'max']
});

extend("isCarrierBarcode", {
  validate(value) {
      return isCarrierBarcodeFormat(value);
  },
  message: "載具條碼格式錯誤",
});