let state = {
    user: {},
    userPoints: [],
    userInfo: {},
    currentPath: "",
    isFinishQuestions: false,
    isQANotExist: false,
    campaign: {},

    muteNotifications: false,

};

export default state