import tyappRoute from './tyapp';
import error from './error';
import scanError from './scanError';
import turntable from './turntable';
import video from './video';
import {payment, pointPayment, paymentResult, paymentError} from './payment';

export default [
    tyappRoute,
    error,
    scanError,
    turntable,
    video,
    payment,
    pointPayment,
    paymentResult,
    paymentError
]