import Vue from 'vue';
import _ from 'underscore';
import moment from 'moment-timezone';
import { db } from '../../db';

const state = () => ({
  notifications: { }, //An id-to-message mapping
  unreadCount: 0,

  // State variable
  hasInit: false,
  notificationPopupEnabled: false
});

const getters = {

  notifications(state) {
    return state.notifications;
  },

  unreadCount(state) {
    return state.unreadCount;
  }

};

const mutations = {

  addNotification(state, noti) {
    // state.notifications[ noti.id ] = noti;
    Vue.set(state.notifications, noti.id, noti);
    if (noti.unread)
      state.unreadCount++;
  },

  setUnreadCount(state, v) {
    Vue.set(state, 'unreadCount', v);
  },

  markRead(state, notiId) {
    state.notifications[ notiId ].unread = false;
    state.unreadCount = Math.max(0, state.unreadCount - 1);
  },

  togglePopup(state) {
    state.notificationPopupEnabled = !state.notificationPopupEnabled;
  },

  setHasInit(state, v) {
    state.hasInit = v;
  }
};

const actions = {

  init({ state, rootState, commit }) {
    // 如果曾經init過，則不需再次init
    if (state.hasInit) {
      return new Promise(resolve => {
        resolve('Notifications already initialized.');
      });
    }

    let userId = rootState.user.userId;
    let firestoreTopicId = rootState.user.user.firestoreChannelId;

    // 先取得最近已讀時間點
    let latestReadAt;
    db.collection(
      `notifications/${process.env.VUE_APP_MERCHANT_ID}`
      + `/recipients/${userId}/readTopics`
    )
    .doc(firestoreTopicId)
    .get()
    .then(snapshot => {
      if (!snapshot.exists)
        latestReadAt = 0;
      else
        latestReadAt = snapshot.data().latestReadAt;
      console.log('lastest read at', moment.unix(latestReadAt).tz('Asia/Taipei').toDate());
      
      // 建立所有notification message的collection listener
      db.collection(
        `notifications/${process.env.VUE_APP_MERCHANT_ID}`
        + `/topics/${firestoreTopicId}/messages`
      )
      .where('ts', '>=', moment().add(-1, 'weeks').startOf('day').unix())
      .orderBy('ts', 'desc')
      .limit(100)//FIXME: 我們限制最多撈取100則message
      .onSnapshot(snapshot => {

        // 標示init完成狀態
        commit('setHasInit', true);

        // 以listener的方式收集所有message，同時監聽更新
        snapshot.docChanges().forEach(change => {
          const msgDoc = change.doc;
          const data = msgDoc.data();
          
          // 新增事件（初次呼叫也會有，所以可以用來新增首批message）
          if (change.type == 'added') {
            const unread = data.unread && (data.ts >= latestReadAt) //latestReadAt以前的都視為已讀
            commit('addNotification', _.extend(data, {
              id: msgDoc.id,
              unread: unread
            }));

            // console.log(`[DEBUG] state.notificationPopupEnabled`, state.notificationPopupEnabled);
            // console.log(`[DEBUG] rootState.muteNotifications`, rootState.muteNotifications);
            // 有條件show通知popup
            if (!state.notificationPopupEnabled)
              return;
            // 可能會臨時mute通知，要檢查此狀態變數  
            if (rootState.muteNotifications === true)
              return;
            const dateTime = moment.unix(data.ts).format('YYYY/M/D h:mm:ssA');
            Vue.notify({
              group: "noti-popup",
              title: data.title,
              text: `${data.text}<br>
                        <span style="color:grey">${dateTime}</span>
                        `,
              foo: "bar",
            });
          }
          // 修改事件，通常是因為被標成已讀
          else if (change.type == 'modified') {
            commit('markRead', msgDoc.id);
          }

        });

      });
    });
   
    //TODO: 之後也要listener公共topic

    // 等待一段時間後才enable notification popup
    // 避免一進入頁面就pop所有過去的通知
    setTimeout(() => {
      commit('togglePopup');
    }, 3000);
  }

};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};